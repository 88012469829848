// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-en-research-publications-js": () => import("./../../../src/pages/en/research/publications.js" /* webpackChunkName: "component---src-pages-en-research-publications-js" */),
  "component---src-pages-fr-404-js": () => import("./../../../src/pages/fr/404.js" /* webpackChunkName: "component---src-pages-fr-404-js" */),
  "component---src-pages-fr-js": () => import("./../../../src/pages/fr.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-fr-research-publications-js": () => import("./../../../src/pages/fr/research/publications.js" /* webpackChunkName: "component---src-pages-fr-research-publications-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-event-listing-js": () => import("./../../../src/templates/eventListing.js" /* webpackChunkName: "component---src-templates-event-listing-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-listing-js": () => import("./../../../src/templates/newsListing.js" /* webpackChunkName: "component---src-templates-news-listing-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-publication-listing-js": () => import("./../../../src/templates/publicationListing.js" /* webpackChunkName: "component---src-templates-publication-listing-js" */)
}

